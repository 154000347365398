import * as React from 'react'
import * as ObjectUtils from '@avcan/utils/object'

export function useParams(schema, initial = {}) {
    const utils = useParamsUtils(schema)
    const initialValueRef = React.useRef(utils.parse(initial))
    const [value, setValue] = React.useState(initialValueRef.current)

    return React.useMemo(() => {
        return {
            value,
            haveChanged: value !== initialValueRef.current,
            format() {
                return utils.format(value)
            },
            isEmpty() {
                return utils.isEmpty(value)
            },
            merge(params) {
                setValue(() => ObjectUtils.clean(Object.assign({}, value, params)))
            },
            clear() {
                setValue(utils.parse({}))
            },
            reset() {
                setValue(initialValueRef.current)
            },
        }
    }, [value, utils])
}

export function useParamsUtils(schema) {
    return React.useMemo(() => {
        const params = Object.entries(schema)

        return {
            parse(query) {
                const values = params.reduce((values, [key, param]) => {
                    values[key] = param.parse(query[key])

                    return values
                }, {})

                return ObjectUtils.clean(values)
            },
            format(values) {
                return params.reduce((query, [key, param]) => {
                    const value = values[key]

                    if (key in values && !param.isEmpty(value)) {
                        query[key] = param.format(value)
                    }

                    return query
                }, {})
            },
            isEmpty(values) {
                if (ObjectUtils.isEmpty(values)) {
                    return true
                }

                return Object.entries(values).every(([property, value]) => schema[property].isEmpty(value))
            },
        }
    }, [schema])
}
