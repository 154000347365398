import * as React from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { FormattedMessage, useIntl } from 'react-intl'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'

import { useVisibility } from 'hooks/storage/session'
import * as URLUtils from '@avcan/utils/url'
import { useSpaws } from 'clients/spaws'
import { StructuredText } from 'prismic/components/base'
import * as Pages from 'components/page'
import * as Sidebars from 'components/sidebar'
import { Tag as BaseTag } from 'components/tag'
import { Page as PageLayout } from 'layouts/pages'
import Head from 'components/Head'
import { InnerHTML } from 'components/misc'
import { SpawImage } from './spawImage'
import SPAWIcon from 'components/icons/spaw/SPAWIcon'

import styles from './spaw.module.css'

export default function Alert() {
    const [visible, hide] = useVisibility('spaw')
    const { data, error } = useSpaws()
    const { route } = useRouter()

    if (!visible || route.startsWith('/spaw') || process.env.NEXT_PUBLIC_MOCK_API === 'true') {
        return null
    }

    if (!data || error) {
        return null
    }

    const href = buildPath(data)

    return (
        <div className={styles.Banner}>
            <BannerSPAWIcon />
            <Link href={href} className={styles.link}>
                <FormattedMessage defaultMessage="Special Public Avalanche Warning" description="SPAW banner" />
            </Link>
            <BannerSPAWIcon />
        </div>
    )
}

function BannerSPAWIcon() {
    return <SPAWIcon width={36} height={36} />
}

function Title({ spaw }) {
    return (
        <React.Fragment>
            {Date.now() > spaw.expiryDateTime ? (
                <BaseTag as={'strong'} className={styles.TagExpired}>
                    <FormattedMessage description="expired" defaultMessage="Expired" />
                </BaseTag>
            ) : (
                <BaseTag as={'strong'} className={styles.TagActive}>
                    <FormattedMessage description="in-effect" defaultMessage="In effect" />
                </BaseTag>
            )}
            <FormattedMessage id="special-public-avalanche-warning" defaultMessage="Special Public Avalanche Warning" />
        </React.Fragment>
    )
}

export function Page({ spaw }) {
    const intl = useIntl()
    const title = intl.formatMessage({
        defaultMessage: 'Special Public Avalanche Warning',
    })

    // Set up Photoswipe Lightbox
    const galleryId = 'spaws'
    React.useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#' + galleryId,
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        lightbox.init()

        return () => {
            lightbox.destroy()
            lightbox = null
        }
    }, [])

    const images = spaw.media.Images
    const generalImages = images.filter(image => image.tag === 'general')
        ? images.filter(image => image.tag === 'general')
        : []
    const avalancheImages = images.filter(image => image.tag === 'avalanche')
        ? images.filter(image => image.tag === 'avalanche')
        : []

    return (
        <PageLayout>
            <Head
                title={title}
                description={title}
                ogImage="https://res.cloudinary.com/avalanche-ca/image/upload/v1459287746/website/AvCan_SPAW_icon.jpg"
            />
            <Pages.Header title={<Title spaw={spaw} />} />
            <Pages.Content>
                <Pages.Main>
                    <Pages.Headline>
                        <StructuredText value={spaw.description} />
                    </Pages.Headline>
                    {spaw.communications?.headline ? (
                        <header className={styles.Headline}>
                            <InnerHTML>{spaw.communications.headline}</InnerHTML>
                        </header>
                    ) : null}
                    <div className="pswp-gallery" id={galleryId}>
                        {generalImages.map(image => (
                            <Image
                                key={image.url}
                                src={image.url}
                                alt={image.tag}
                                width={image.width}
                                height={image.height}
                                loading="lazy"
                                quality={'auto'}
                                sizes="100vw"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                }}
                            />
                        ))}
                        {spaw && <InnerHTML>{spaw.message}</InnerHTML>}
                        {avalancheImages.map(image => (
                            <SpawImage key={image.id} image={image} galleryId={galleryId} />
                        ))}
                    </div>
                </Pages.Main>
                <Pages.Aside>
                    <Sidebars.Sidebar>
                        {/* <Sidebars.Item>
                                <Link href="/spaw/faq">
                                        <FormattedMessage
                                            description="Layout SPAW"
                                            defaultMessage="What is a SPAW?"
                                            />
                                </Link>
                            </Sidebars.Item> */}
                        <Sidebars.Contact />
                        <Sidebars.Share />
                    </Sidebars.Sidebar>
                </Pages.Aside>
            </Pages.Content>
        </PageLayout>
    )
}

export function NoActiveSpaw() {
    const intl = useIntl()

    const title = intl.formatMessage({
        defaultMessage: 'Special Public Avalanche Warning',
    })

    return (
        <PageLayout>
            <Head
                title={title}
                description={title}
                ogImage="https://res.cloudinary.com/avalanche-ca/image/upload/v1459287746/website/AvCan_SPAW_icon.jpg"
            />
            <Pages.Header
                title={
                    <FormattedMessage
                        id="special-public-avalanche-warning"
                        defaultMessage="Special Public Avalanche Warning"
                    />
                }
            />
            <Pages.Content>
                <Pages.Main>
                    <Pages.Headline>
                        <FormattedMessage
                            description="Layout SPAW"
                            defaultMessage="There is currently no Special Public Avalanche Warning in effect."
                        />
                    </Pages.Headline>
                </Pages.Main>
                <Pages.Aside>
                    <Sidebars.Sidebar>
                        {/* <Sidebars.Item>
                                <Link href="/spaw/faq">
                                        <FormattedMessage
                                            description="Layout SPAW"
                                            defaultMessage="What is a SPAW?"
                                            />
                                </Link>
                            </Sidebars.Item> */}
                        <Sidebars.Contact />
                    </Sidebars.Sidebar>
                </Pages.Aside>
            </Pages.Content>
        </PageLayout>
    )
}

export function SpawNotFound() {
    const intl = useIntl()

    const title = intl.formatMessage({
        defaultMessage: 'Special Public Avalanche Warning',
    })

    return (
        <PageLayout>
            <Head
                title={title}
                description={title}
                ogImage="https://res.cloudinary.com/avalanche-ca/image/upload/v1459287746/website/AvCan_SPAW_icon.jpg"
            />
            <Pages.Header
                title={
                    <FormattedMessage
                        id="special-public-avalanche-warning"
                        defaultMessage="Special Public Avalanche Warning"
                    />
                }
            />
            <Pages.Content>
                <Pages.Main>
                    <Pages.Headline>
                        <FormattedMessage
                            description="Layout SPAW"
                            defaultMessage="The Special Public Avalanche Warning you are looking for does not exist."
                        />
                    </Pages.Headline>
                </Pages.Main>
                <Pages.Aside>
                    <Sidebars.Sidebar>
                        {/* <Sidebars.Item>
                                <Link href="/spaw/faq">
                                        <FormattedMessage
                                            description="Layout SPAW"
                                            defaultMessage="What is a SPAW?"
                                            />
                                </Link>
                            </Sidebars.Item> */}
                        <Sidebars.Contact />
                    </Sidebars.Sidebar>
                </Pages.Aside>
            </Pages.Content>
        </PageLayout>
    )
}

// Utils
function buildPath(spaw) {
    return URLUtils.buildPath('/spaw', spaw?.id)
}
