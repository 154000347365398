import * as React from 'react'
import { throttle } from 'lodash'
import { Local, Session } from 'services/storage'
import { captureMessage } from 'services/sentry'

export function useBoolean(initial) {
    const [bool, toggle] = useToggle(initial)
    const activate = React.useCallback(() => {
        toggle(true)
    }, [toggle])
    const deactivate = React.useCallback(() => {
        toggle(false)
    }, [toggle])
    const reset = React.useCallback(() => {
        toggle(initial)
    }, [toggle, initial])

    return [bool, activate, deactivate, toggle, reset]
}

export function useToggle(initial) {
    const [bool, set] = React.useState(initial)
    const toggle = React.useCallback(next => {
        if (typeof next === 'boolean') {
            set(next)
        } else {
            set(current => !current)
        }
    }, [])

    return [bool, toggle]
}

// TODO Improve that hook, the siganature should be the same as "setTimeout"
export function useTimeout(elapse = 0) {
    const [ready, setReady] = useBoolean(false)

    React.useEffect(() => {
        const timer = setTimeout(setReady, elapse)

        return () => {
            clearTimeout(timer)
        }
    }, [elapse])

    return ready
}

export function useWindowSize(wait = 250) {
    const [size, setSize] = useSafeState(getWindowSize())
    const handleResize = React.useCallback(
        throttle(() => {
            setSize(getWindowSize())
        }, wait),
        [wait]
    )

    useEventListener('resize', handleResize)
    useEventListener('orientationchange', handleResize)

    return size
}

export function useDimensions() {
    const ref = React.useRef(null)
    const [dimensions, setDimensions] = React.useState(null)
    const { current } = ref

    React.useLayoutEffect(() => {
        if (!current) {
            return
        }

        const { width, height } = current.getBoundingClientRect()

        setDimensions({ width, height })
    }, [current])

    return [ref, dimensions]
}

export function useEventListener(eventName, handler, element = typeof window === 'undefined' ? null : window) {
    React.useEffect(() => {
        if (element === null || typeof element?.addEventListener !== 'function' || typeof handler !== 'function') {
            return
        }

        element.addEventListener(eventName, handler, false)

        return () => {
            element.removeEventListener(eventName, handler, false)
        }
    }, [eventName, element, handler])
}

// DOM APIs
export function useNetworkInformation() {
    const [connection, setConnection] = React.useState(getConnection())

    React.useEffect(() => {
        function updateConnectionStatus() {
            setConnection(getConnection())
        }

        if (!connection) {
            return
        }

        connection.addEventListener('change', updateConnectionStatus)
        return () => {
            connection.removeEventListener('change', updateConnectionStatus)
        }
    }, [connection])

    return connection
}
function getConnection() {
    if (typeof navigator === 'undefined') {
        return
    }

    return navigator.connection || navigator.mozConnection || navigator.webkitConnection
}

// Storage
function useStorage(storage, key, defaultValue = null) {
    const [value, setValue] = React.useState(() => storage.get(key, defaultValue))

    React.useEffect(() => {
        storage.set(key, value)
    }, [value])

    return [value, setValue]
}

export function useLocalStorage(key, defaultValue) {
    return useStorage(Local, key, defaultValue)
}

export function useSessionStorage(key, defaultValue) {
    return useStorage(Session, key, defaultValue)
}

export function useCounter(
    initialCounter = 0,
    min = Number.MIN_SAFE_INTEGER,
    max = Number.MAX_SAFE_INTEGER,
    cycle = false
) {
    const [counter, setCounter] = React.useState(initialCounter)
    function increment(step = 1) {
        const value = counter + step

        setCounter(value > max && cycle ? min : value)
    }
    function decrement(step = 1) {
        const value = counter - step

        setCounter(value < min && cycle ? max : value)
    }
    function first() {
        setCounter(min)
    }
    function last() {
        setCounter(max)
    }

    return [counter, increment, decrement, first, last]
}

export function useClientRect(initialRect) {
    const [rect, setRect] = React.useState(initialRect)
    const node = React.useRef(null)
    const ref = React.useCallback(current => {
        if (current) {
            setRect(current.getBoundingClientRect())
            node.current = current
        }
    }, [])

    // FIXME Use ResizeObserver instead, but it requires a polyfill!
    React.useEffect(() => {
        if (node.current) {
            setRect(node.current.getBoundingClientRect())
        }
    }, [useWindowSize()])

    return [rect, ref]
}

export function useRatio(x = 16, y = 9) {
    const [rect, ref] = useClientRect()
    const dimensions = React.useMemo(() => {
        if (!rect) {
            return null
        }

        const { width } = rect

        return {
            width: Math.round(width),
            height: Math.round(width * (y / x)),
        }
    }, [rect])

    return [dimensions, ref]
}

export function useFullscreen() {
    const fullscreenEnabled = typeof document === 'undefined' ? false : document.fullscreenEnabled
    const ref = React.useRef(null)
    const enter = React.useCallback(() => {
        const { current } = ref

        if (!current || !document.fullscreenEnabled) {
            return
        }

        try {
            if (current.requestFullscreen) {
                current.requestFullscreen()
            } else if (current.mozRequestFullScreen) {
                current.mozRequestFullScreen()
            } else if (current.webkitRequestFullscreen) {
                current.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
            } else if (current.msRequestFullscreen) {
                current.msRequestFullscreen()
            }
        } catch (error) {
            captureMessage('Can not request Fullscreen. ' + error.message)
        }
    }, [ref.current])
    const exit = React.useCallback(() => {
        if (!getFullscreenElement()) {
            return
        }

        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        }
    }, [])
    const toggle = React.useCallback(() => {
        getFullscreenElement() ? exit() : enter()
    }, [enter])

    return [ref, enter, exit, toggle, fullscreenEnabled]
}

export function useScroll(ref) {
    const [position, setPosition] = React.useState([0, 0]) // Could set position after mounted
    const handler = React.useCallback(
        throttle(event => {
            const { scrollLeft, scrollTop } = event.target

            setPosition([scrollTop, scrollLeft])
        }, 250),
        []
    )

    useEventListener('scroll', handler, ref.current)

    return position
}

export function useMounted() {
    const [mounted, setMounted] = React.useState(false)

    React.useEffect(() => {
        setMounted(true)

        return () => {
            setMounted(false)
        }
    }, [])

    return mounted
}

// Utils
export function getWindowSize(defaults) {
    if (typeof window === 'undefined') {
        return Object.assign({ width: null, height: null }, defaults)
    }

    return {
        width: window.innerWidth,
        height: window.innerHeight,
    }
}
function getFullscreenElement() {
    return (
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement ||
        document.msFullscreenElement
    )
}
function useSafeState(initialState) {
    const mounted = useMounted()
    const [state, setState] = React.useState(initialState)
    const setStateSafely = React.useCallback(state => {
        if (mounted.current) {
            setState(state)
        }
    }, [])

    return [state, setStateSafely]
}
