import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import * as Auth0 from '@auth0/auth0-react'
import * as Config from 'services/auth/config'
import * as Sentry from 'services/sentry'
import * as Pages from 'layouts/pages'
import * as URLUtils from '@avcan/utils/url'
import { FormattedMessage } from 'react-intl'
import * as Env from 'env'

Provider.propTypes = {
    children: PropTypes.node.isRequired,
}

export function Provider({ children }) {
    const { replace } = useRouter()
    const path = Env.server ? process.env.NEXT_PUBLIC_SITE_ORIGIN_URL : document.location.origin
    const redirectUri = URLUtils.buildPath(path, 'authenticate')
    const handleRedirectCallback = React.useCallback(
        (appState = {}) => {
            const { returnTo = '/', params } = appState
            const url = URLUtils.appendParams(returnTo, params)

            replace(url)
        },
        [replace]
    )

    return (
        <Auth0.Auth0Provider
            domain={Config.domain}
            clientId={Config.clientId}
            audience={Config.audience}
            authorizationParams={{
                redirect_uri: redirectUri,
            }}
            cacheLocation="localstorage"
            onRedirectCallback={handleRedirectCallback}
        >
            {children}
            <UserListener />
        </Auth0.Auth0Provider>
    )
}

// Hooks
export function useAuth() {
    return Auth0.useAuth0()
}
export function useLoading() {
    const { isLoading } = useAuth()

    return isLoading
}

export function useUsername() {
    const { user } = useAuth()

    return user?.user_metadata?.nickname || user?.nickname
}

export function withAuthenticationRequired(Component, options = {}) {
    return Auth0.withAuthenticationRequired(Component, {
        onRedirecting() {
            const title = <FormattedMessage defaultMessage="Verifying authentication..." />

            return <Pages.Loading title={title} />
        },
        ...options,
    })
}

// Util
function UserListener() {
    const { user } = useAuth()

    React.useEffect(() => {
        if (user) {
            Sentry.setUserContext(user)
        }
    }, [user])

    return null
}
