import * as React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import * as Texts from 'components/text'
import { Splash } from 'components/page/sections'
import Entry from './Entry'
import EntrySet from './EntrySet'

FeedSplash.propTypes = {
    children: PropTypes.node,
}

export default function FeedSplash({ children, documents }) {
    const featured = documents.find(isFeatured) || documents[0]
    const others = documents.filter(p => p !== featured)

    return (
        <Splash>
            {children}
            {documents.length === 0 ? (
                <Texts.Muted>
                    <FormattedMessage description="Layout feed/Splash" defaultMessage="Nothing found." />
                </Texts.Muted>
            ) : (
                <React.Fragment>
                    {featured && <EntrySet>{createEntry(featured)}</EntrySet>}
                    {others.length > 0 && <EntrySet>{others.map(createEntry)}</EntrySet>}
                </React.Fragment>
            )}
        </Splash>
    )
}

// Utils
function createEntry(document) {
    return <Entry condensed key={document.uid} {...document} />
}
function isFeatured({ tags }) {
    return tags.includes('featured')
}
