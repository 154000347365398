import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Button from './Button'
import { SUBTILE } from './kinds'
import styles from './Button.module.css'

Close.propTypes = {
    className: PropTypes.string,
    children: PropTypes.element,
}

export default function Close({ children = '×', title, ...props }) {
    const intl = useIntl()

    return (
        <Button
            kind={SUBTILE}
            title={
                title
                    ? title
                    : intl.formatMessage({
                          defaultMessage: 'Close',
                          description: 'Close Button',
                      })
            }
            {...props}
            className={clsx(styles.Close, props.className)}
        >
            <span aria-hidden="true">{children}</span>
        </Button>
    )
}
