import React from 'react'
import Icon from './Icon'

export default function Instagram(props) {
    return (
        <Icon {...props} viewBox="0 0 551.034 551.034">
            <g>
                <linearGradient
                    id="gradient1"
                    gradientUnits="userSpaceOnUse"
                    x1="275.517"
                    y1="4.57"
                    x2="275.517"
                    y2="549.72"
                    gradientTransform="matrix(1 0 0 -1 0 554)"
                >
                    <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                    <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                    <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                    <stop offset="1" style={{ stopColor: '#7024C4' }} />
                </linearGradient>
                <path
                    style={{ fill: 'url(#gradient1)' }}
                    d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722
                    c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156
                    C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156
                    c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722
                    c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z"
                />

                <linearGradient
                    id="gradient2"
                    gradientUnits="userSpaceOnUse"
                    x1="275.517"
                    y1="4.57"
                    x2="275.517"
                    y2="549.72"
                    gradientTransform="matrix(1 0 0 -1 0 554)"
                >
                    <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                    <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                    <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                    <stop offset="1" style={{ stopColor: '#7024C4' }} />
                </linearGradient>
                <path
                    style={{ fill: 'url(#gradient2)' }}
                    d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517
                    S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083
                    s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z"
                />

                <linearGradient
                    id="gradient3"
                    gradientUnits="userSpaceOnUse"
                    x1="418.31"
                    y1="4.57"
                    x2="418.31"
                    y2="549.72"
                    gradientTransform="matrix(1 0 0 -1 0 554)"
                >
                    <stop offset="0" style={{ stopColor: '#E09B3D' }} />
                    <stop offset="0.3" style={{ stopColor: '#C74C4D' }} />
                    <stop offset="0.6" style={{ stopColor: '#C21975' }} />
                    <stop offset="1" style={{ stopColor: '#7024C4' }} />
                </linearGradient>
                <circle style={{ fill: 'url(#gradient3)' }} cx="418.31" cy="134.07" r="34.15" />
            </g>
        </Icon>
    )
}
