import * as React from 'react'
import PropTypes from 'prop-types'
import styles from './Pagination.module.css'
import Segment from './Segment'
import pagination from 'utils/pagination'

Pagination.propTypes = {
    total: PropTypes.number.isRequired,
    active: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default function Pagination({ total = 0, active = 1, onChange }) {
    const segments = React.useMemo(() => {
        const ceil = Math.ceil(total)

        if (ceil < 2) {
            return null
        }

        return ceil <= 10 ? generateSequence(ceil) : pagination(active, ceil, 3, null)
    }, [total, active])

    if (!segments) {
        return null
    }

    return (
        <div className={styles.Container}>
            {segments.map(page => {
                if (typeof page !== 'number') {
                    return (
                        <Segment key={page} disabled>
                            …
                        </Segment>
                    )
                }

                return <Segment key={page} page={page} onActivate={onChange} disabled={active === page} />
            })}
        </div>
    )
}

// Utils
function generateSequence(length) {
    return Array(length)
        .fill(1)
        .map((value, index) => value + index)
}
