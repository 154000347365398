import * as React from 'react'

import PropTypes from 'prop-types'

import * as Hooks from 'hooks'

Provider.propTypes = {
    children: PropTypes.element,
}

export function Provider({ children }) {
    // If these get more complicated, let's combine them into a single "user-settings" localStorage key
    const [colourblindModeEnabled, setColourblindModeEnabled] = Hooks.useLocalStorage('colourblind-mode', false)

    function toggleColourBlindMode() {
        setColourblindModeEnabled(!colourblindModeEnabled)
    }

    const value = {
        colourblindModeEnabled,
        toggleColourBlindMode,
    }

    return <context.Provider value={value}>{children}</context.Provider>
}

export function useUserSettings() {
    return React.useContext(context)
}

const context = React.createContext()
