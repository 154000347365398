import NextHead from 'next/head'

export default function Head({
    title = 'Avalanche Canada',
    description,
    ogImage = 'https://res.cloudinary.com/avalanche-ca/image/upload/bo_20px_solid_rgb:fff,c_pad,h_315,w_600/v1413919754/logos/avalanche_canada_left_quqmls.jpg',
    ogTitle = title,
    ogDescription = description,
}) {
    return (
        <NextHead>
            <title key="title">{title}</title>
            <meta name="description" content={description} />
            <meta key="og-title" property="og:title" content={ogTitle} />
            <meta key="og-image" content={ogImage} property="og:image" />
            <meta key="twitter-card" content="summary_large_image" property="twitter:card" />
            <meta key="twitter-site" content="@avalancheca" property="twitter:site" />
            {ogDescription && <meta key="og-description" property="og:description" content={ogDescription}></meta>}
        </NextHead>
    )
}
